@import '../../../assets/global-style/mixin';

.link {
    font-family: var(--font-primery);
    font-size: 14px;
    line-height: 100%;
    color: var(--color-blue-first);
    letter-spacing: 0.04em;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: 0.5s;
    cursor: pointer;

    @include before_768 {
        font-size: 12px;
    }

    &:hover {
        border-bottom: 1px solid var(--color-blue-first);
    }

    &--dash {
        border-bottom: 1px dashed var(--color-blue-first);

        &:hover {
            border-bottom: 1px dashed transparent;
        }
    }

    &--fs14 {
        font-size: 14px;
        letter-spacing: 0.04em;
    }

    &--fs15 {
        font-size: 15px;
        letter-spacing: 0.04em;
    }

    &--fs16 {
        font-size: 16px;
    }

    &--fs17 {
        font-size: 17px;

        @include before_768 {
            font-size: 15px;
        }
    }

    &--mb24 {
        display: inline-block;
        margin-bottom: 24px;
    }
}
