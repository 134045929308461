@import '../../../assets/global-style/mixin';

.title {
    margin-bottom: 24px;
    @include adaptive-font(26, 18, 1240);
    line-height: 140%;
    font-weight: 400;
    color: var(--color-black-first);

    &--authorization {
        @include adaptive-font(24, 18, 1240);
    }

    &--fs17 {
        font-weight: 600;
        @include adaptive-font(17, 15, 1240);
    }

    &--fs17n {
        @include adaptive-font(17, 15, 1240);
    }

    &--center {
        text-align: center;
    }

    &--mb40 {
        margin-bottom: 40px;

        @include before_768 {
            margin-bottom: 24px;
        }
    }

    &--mb12 {
        margin-bottom: 12px;
    }
}
