@import '../../../assets/global-style/mixin';

.cell {
    --width-cell-name: auto;
    --width-border-right: calc(100% - 12px);
    padding-top: 7px;

    &--mb24 {
        margin-bottom: 24px;
    }

    &--mb8 {
        margin-bottom: 8px;
    }

    &__content {
        height: 100%;
        position: relative;
        padding: 0 0 14px 0;
        border-bottom: 1px solid var(--color-gray-seventh);
        border-left: 1px solid var(--color-gray-seventh);
        border-right: 1px solid var(--color-gray-seventh);
        border-radius: 8px;
        transition: 0.5s;
    }

    &__border-box {
        display: flex;
        width: 100%;
        height: 14px;
    }

    &__border-left {
        flex-shrink: 0;
        width: 12px;
        border-top: 1px solid var(--color-gray-seventh);
        border-top-left-radius: 6px;
        transition: 0.5s;
    }

    &__border-content {
        position: relative;
        flex-shrink: 0;
        top: 25px;
        display: flex;
        align-items: center;
        transform: translateY(-50%);
        padding: 10px 12px 10px;
        transition: 0.5s;
    }

    &__border-right {
        position: absolute;
        top: 0;
        right: 0;
        width: var(--width-border-right);
        height: 8px;
        min-width: 24px;
        margin-left: auto;
        border-top: 1px solid var(--color-gray-seventh);
        border-top-right-radius: 6px;
        transition: 0.5s;
    }

    &__name {
        position: relative;
        width: var(--width-cell-name);
        font-weight: 300;
        padding-left: 18px;
        font-size: 17px;
        line-height: 100%;
        color: var(--color-gray-first);
        background-color: transparent;
        transition: 0.5s;

        @include before_768 {
            font-size: 14px;
        }
    }

    &__name-star,
    &__name-successful {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    }

    &__name-star {
        opacity: 1;
        visibility: visible;
        transition: 0.3s;
    }

    &__name-successful {
        opacity: 0;
        visibility: hidden;
        transition: 0.5s;
    }

    &__data {
        position: relative;
        padding: 0 12px;

        @include before_768 {
            font-size: 14px;
        }

        &--icon {
            padding-right: 44px;
        }
    }

    &__label-buffer {
        position: absolute;
        left: -2000px;
        top: -2000px;
        display: flex;
        align-items: center;
        padding: 12px;
        font-weight: 300;
        font-size: 14px;
        line-height: 100%;
        visibility: hidden;
        white-space: nowrap;

        @include before_768 {
            font-size: 12px;
        }
    }

    &__current-value {
        margin-top: 6px;
        font-size: 14px;
        line-height: 140%;
        color: var(--color-black-first-rgba50);

        @include before_768 {
            font-size: 12px;
        }
    }

    &__error-message {
        margin-top: 6px;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: var(--error-primery-color);
        display: none;
    }

    &__identificator {
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translate(0, -50%);
    }

    &__icon {
        display: block;
    }

    &.js-label-up {
        .cell__border-content {
            top: 0;
        }

        .cell__name {
            font-size: 14px;
            padding-left: 0;

            @include before_768 {
                font-size: 12px;
            }
        }

        .cell__name-star {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.js-label-successful {
        .cell__name {
            font-size: 14px;
            padding-left: 22px;

            @include before_768 {
                font-size: 12px;
            }
        }

        .cell__name-successful {
            opacity: 1;
            visibility: visible;
        }

        .cell__label-buffer {
            padding-left: 34px;
        }

        .cell__name-star {
            opacity: 0;
            visibility: hidden;
        }
    }

    &.js-no-required {
        .cell__name-star {
            display: none;
        }

        .cell__name-successful {
            opacity: 0;
            visibility: hidden;
        }

        .cell__name {
            padding-left: 18px;
            font-size: 17px;

            @include before_768 {
                font-size: 14px;
            }
        }

        &.js-label-up {
            .cell__name-successful {
                opacity: 1;
                visibility: visible;
            }

            .cell__name {
                font-size: 14px;
                padding-left: 22px;

                @include before_768 {
                    font-size: 12px;
                }
            }
        }
    }

    &.js-cell-error {
        .cell__content {
            border-color: var(--error-primery-color);
        }

        .cell__border-left {
            border-color: var(--error-primery-color);
        }

        .cell__border-right {
            border-color: var(--error-primery-color);
        }
    }

    &.js-cell-disabled {
        .cell__content {
            background-color: var(--color-gray-second);
            pointer-events: none;
            cursor: not-allowed;
        }

        .cell__border-content {
            border-radius: 8px;
            // padding-top: 10px;
            background-color: var(--color-gray-second);
        }
    }

    &.js-cell-hidden {
        display: none;
    }
}
.cell__label-buffer {
    position: absolute;
    left: -2000px;
    top: -2000px;
    display: flex;
    align-items: center;
    padding: 12px;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    visibility: hidden;
    white-space: nowrap;

    @include before_768 {
        font-size: 12px;
    }
}
