.icon {
    fill: var(--icon-primery-color);

    &--black {
        fill: var(--color-black-first);
    }

    &--white {
        fill: var(--color-white-first);
    }

    &--red {
        fill: var(--error-primery-color);
    }
}
