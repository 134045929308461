@import '../../../assets/global-style/mixin';

.delimiter-message {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__el {
        &--left,
        &--right {
            flex-grow: 1;
            height: 1px;
            background-color: var(--color-gray-third);
        }

        &--message {
            flex-shrink: 0;
            padding: 0 6px;
            font-weight: 300;
            font-size: 12px;
            line-height: 18px;
            color: var(--color-gray-first);
            background-color: transparent;
        }
    }
}
