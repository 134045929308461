@import '../../../assets/global-style/mixin';

.menu {
    @include before_768 {
        position: fixed;
        // display: none;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100vh - 50px);
        padding: 30px 15px;
        background-color: var(--color-white-first);
        transition: 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        transform: translate(-50px, 0);
    }

    &.js-menu-active {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0);
    }

    &__list {
        display: flex;
        border-bottom: 2px solid var(--color-gray-third);

        @include before_768 {
            flex-direction: column;
            align-items: flex-start;
            border-bottom: none;
        }
    }

    &__item {
        position: relative;
        padding-bottom: 6px;

        @include before_768 {
            padding-bottom: 0;
        }

        &.active {
            .menu__link {
                color: var(--color-black-first);
            }

            .menu__line-box {
                display: block;

                @include before_768 {
                    display: none;
                }
            }
        }

        &.active-note {
            .menu__notification {
                display: block;
            }
        }

        &:not(:last-child) {
            margin-right: 40px;

            @include before_768 {
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__link {
        font-family: var(--font-primery);
        font-size: 14px;
        line-height: 18px;
        color: var(--color-gray-first);
        text-decoration: none;
        transition: opacity 0.5s;

        &:hover {
            opacity: 0.6;
        }
    }

    &__line-box {
        display: none;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 6px;
        overflow: hidden;
    }

    &__line {
        position: absolute;
        bottom: -3px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-blue-first);
        border-radius: 36px;
    }

    &__notification {
        display: none;
        position: absolute;
        top: -3px;
        right: -10px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--color-red-first);
    }

    &__logo-link {
        display: none;

        @include before_768 {
            display: block;
            margin-bottom: 30px;
        }
    }
}
