.link-icon {
    display: flex;
    align-items: center;
    font-family: var(--font-primery);
    text-decoration: none;

    &__el {
        &:first-child {
            margin-right: 8px;
        }

        &--text {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: var(--color-gray-first);
        }

        &--svg {
            flex-shrink: 0;
            fill: var(--icon-primery-color);
        }
    }

    &--blue-text {
        .link-icon__el--text {
            color: var(--color-blue-first);
        }
    }

    &--fs15 {
        .link-icon__el--text {
            font-size: 15px;
        }
    }

    &--blue {
        .link-icon__el {
            &--text {
                color: var(--color-blue-first);
            }

            &--svg {
                fill: var(--color-blue-first);
            }
        }
    }
}
