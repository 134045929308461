@import '../../../assets/global-style/mixin';

.footer {
    flex-shrink: 0;
    padding: 50px 0 32px;
    background-color: #f8fafc;
    position: relative;

    @include before_768 {
        padding: 50px 0;
    }

    &__desktop {
        display: flex;
        flex-direction: column;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;

        @include before_768 {
            flex-direction: column;
        }

        &:last-child {
            margin-bottom: 0;
            align-items: center;
        }

        &--mobile {
            display: none;

            @include before_768 {
                display: flex;
            }
        }

        &--backward {
            @include before_768 {
                flex-direction: column-reverse;
            }
        }

        &--zero {
            @include before_768 {
                margin-bottom: 0;
            }
        }
    }

    &__list--margin {
        margin-bottom: 30px;
    }

    &__el {
        width: 30%;
        padding-right: 30px;

        @include before_768 {
            width: 100%;
            margin-bottom: 24px;
            //padding: 0 20px;
        }

        &--desktop {
            display: flex;
            align-items: center;

            @include before_768 {
                display: none;
            }
        }

        &--tab {
            @include before_768 {
                margin-bottom: 0;
                padding: 16px 20px;
                border-bottom: 1px solid var(--color-blue-first);
            }

            &:first-of-type {
                @include before_768 {
                    border-top: 1px solid var(--color-blue-first);
                }
            }

            &.js-footer-tab-active {
                .footer__arrow {
                    transform: rotate(180deg);
                }
            }
        }

        &--note {
            width: 50%;

            @include before_768 {
                width: 100%;
            }
        }

        &--additionally {
            width: 100%;
            padding-right: 0;

            @include before_768 {
                padding-right: 20px;
            }

            &:first-of-type {
                border-top: none;
            }

            .footer__list {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                @include before_768 {
                    display: none;
                }
            }

            .footer__item {
                width: 30%;
                padding-right: 30px;

                @include before_768 {
                    width: 100%;
                    padding-right: 0;
                }
            }
        }

        &--log {
            display: flex;
            flex-direction: column;
        }

        .icon-logo {
            max-width: 100%;
            margin-bottom: 18px;
        }
    }

    &__name,
    &__link {
        color: #505153;
        text-decoration: none;
    }

    &__tel {
        font-weight: 500;
        font-size: 24px;
        line-height: 30px;
        color: var(--color-blue-first);
        text-decoration: none;
    }

    &__name {
        margin-bottom: 5px;
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        color: var(--color-gray-fourth);

        @include before_768 {
            margin-bottom: 0;
        }
    }

    &__link-box:not(:last-child) {
        margin-bottom: 8px;
    }

    &__link {
        cursor: pointer;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-gray-fourth);

        @include before_768 {
            font-size: 14px;
        }
    }

    &__link--top,
    &__link--bottom {
        font-size: 14px;
        line-height: 20px;
        text-decoration: none;
    }

    &__link--bottom {
        font-size: 16px;
        font-weight: 500;
    }

    &__link-description {
        font-size: 12px;
        line-height: 14px;
        color: var(--text-primery-color);
        opacity: 0.8;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 12px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: var(--color-gray-fourth);
        transition: 0.5s;

        @include before_768 {
            margin-bottom: 0;
            font-size: 12px;
            color: var(--color-gray-fourth);
            cursor: pointer;
        }

        &--active {
            margin-bottom: 16px;
        }
    }

    &__list {
        &--tab {
            @include before_768 {
                display: none;
            }
        }
    }

    &__arrow {
        display: none;
        fill: var(--icon-primery-color);
        transition: 0.5s;

        @include before_768 {
            display: block;
        }
    }

    &__item {
        margin-bottom: 8px;
    }

    &__block {
        @include before_768 {
            margin-bottom: 60px;
        }
    }

    &__signature {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: var(--color-gray-fourth);
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: rgba(175, 175, 175, 0.2);

        @include before_768 {
            display: none;
        }
    }
}
