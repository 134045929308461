@import '../../../assets/global-style/mixin';

.param-checkbox {
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
    }
    // &__input {
    //     display: none;
    // }

    &__item {
        margin-bottom: 12px;

        &:not(:last-child) {
            margin-right: 12px;
        }
    }

    &__label {
        position: relative;
        display: flex;
        align-items: center;
        padding: 6px 12px;
        background-color: var(--color-gray-second);
        border-radius: 50px;
        cursor: pointer;
        transition: opacity 0.5s, padding-left 0.5s;

        &:hover {
            opacity: 0.7;
        }
    }

    &__icon {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translate(0, -50%);
        opacity: 0;
        margin-right: 10px;
        fill: var(--color-blue-first);
        transition: opacity 0.5s;
    }

    &__name {
        font-size: 16px;
        line-height: 100%;
        color: var(--text-primery-color);

        @include before_768 {
            font-size: 14px;
        }
    }

    &__input:checked + .param-checkbox__label {
        padding-left: 30px;
        background-color: var(--color-blue-second);

        .param-checkbox__icon {
            opacity: 1;
        }

        .param-checkbox__name {
            color: var(--color-blue-first);
        }
    }

    &__error-message {
        display: none;
        text-align: center;
        margin-top: 6px;
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: var(--error-primery-color);
    }
}
