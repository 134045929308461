@import '../../../assets/global-style/mixin';

.param-radiobutton {
    display: inline-flex;
    flex-direction: column;

    @include before_480 {
        display: flex;
    }

    &__list {
        display: flex;
        margin-bottom: 24px;
        padding: 6px;
        background-color: var(--color-gray-second);
        border-radius: 8px;

        @include before_768 {
            flex-direction: column;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 14px 24px;
        font-size: 16px;
        line-height: 100%;
        color: var(--color-black-first-rgba60);
        border-radius: 8px;
        cursor: pointer;
        transition: 0.5s;

        @include before_768 {
            padding: 14px 20px;
            font-size: 14px;
        }

        // &.active {
        //     color: var(--color-black-first);
        //     background-color: var(--color-white-first);
        //     box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);
        // }

        &:hover {
            color: var(--color-black-first);
        }
    }

    // &__name {
    //     font-size: 16px;
    //     line-height: 100%;
    //     color: var(--text-primery-color);
    // }

    &__input:checked + .param-radiobutton__name {
        color: var(--color-black-first);
        background-color: var(--color-white-first);
        box-shadow: 0px 2px 2px rgba(50, 50, 71, 0.06), 0px 2px 4px rgba(50, 50, 71, 0.06);

        // .param-radiobutton__name {
        //     color: var(--color-blue-first);
        // }
    }

    &--small {
        .param-radiobutton {
            &__list {
                margin-bottom: 0;
            }

            &__name {
                padding: 8px 10px;
                font-size: 14px;
                border-radius: 8px;
            }
        }
    }
}
