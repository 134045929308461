@import '../../../assets/global-style/mixin';

.radiobutton {
    margin-bottom: 24px;

    &__list {
        display: flex;
        flex-direction: column;

        &--row {
            flex-direction: row;

            @include before_768 {
                flex-direction: column;
            }

            .radiobutton__item {
                &:not(:last-child) {
                    margin-bottom: 0;
                    margin-right: 24px;

                    @include before_768 {
                        margin-right: 0;
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }

    &__item {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-right: 40px;
    }

    &__window {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        margin-right: 12px;
        background: var(--color-gray-second);
        border-radius: 50%;
        border: 1px solid var(--color-gray-sixth);
    }

    &__circle {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--color-blue-first);
        transform: scale(0);
        transition: 0.5s;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--color-gray-second);
        }
    }

    &__name {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: var(--text-primery-color);
        cursor: pointer;
    }

    &__input:checked + .radiobutton__name {
        .radiobutton__window {
            border-color: transparent;
        }

        .radiobutton__circle {
            transform: scale(1);
        }
    }

    &__icon {
        width: 40px;
        height: auto;
        margin-left: 12px;
    }

    &.js-radiobutton-disabled {
        .radiobutton__item {
            pointer-events: none;
            opacity: 0.6;
        }
    }
}
