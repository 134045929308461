@import '../../../assets/global-style/mixin';

.checkbox {
    &--margin {
        margin-bottom: 24px;
    }

    &__item {
        display: flex;
        flex-shrink: 0;
        align-items: center;

        &--half {
            width: 50%;
            margin-bottom: 30px;
        }
    }

    &__window {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        margin-right: 12px;
        background: transparent;
        border: 1px solid var(--color-blue-first);
        border-radius: 1px;
    }

    &__icon-mark {
        fill: var(--color-blue-first);
        opacity: 0;
        transition: 0.5s;
    }

    &__name {
        display: flex;
        align-items: flex-start;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.04em;
        color: var(--text-primery-color);
        cursor: pointer;

        &--info {
            margin-right: 12px;
            min-width: 126px;
        }
    }

    &__input:checked + .checkbox__name {
        .checkbox__icon-mark {
            opacity: 1;
        }
    }

    &__link {
        color: var(--color-blue-first);
        text-decoration: none;
        border-bottom: 1px solid var(--color-blue-first);
        transition: 0.5s;

        &:hover {
            border-bottom: 1px solid transparent;
        }
    }

    &__text {
        &--margin {
            margin-right: 5px;
        }
    }

    &__error-message {
        display: none;
        margin-top: 5px;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.04em;
        color: var(--error-primery-color);
    }

    &.js-checkbox-hidden {
        display: none;
    }
}
