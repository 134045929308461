.wrapper {
    width: 100%;
    max-width: 1240px;
    padding: 0 50px;
    margin: 0 auto;
    box-sizing: border-box;

    @include before_768 {
        padding: 0 15px;
    }

    &--h100 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &--authorization {
        max-width: 500px;
        padding: 80px 0 0;
    }
}
