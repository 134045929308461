//Main imports
@import '../../components/buttons/Button/Button';
@import '../../components/structure/Grid/grid';
@import '../../components/structure/Header/header';
@import '../../components/structure/Navigation/navigation';
@import '../../components/structure/Menu/menu';
@import '../../components/structure/Main/main';
@import '../../components/structure/Wrapper/wrapper';
@import '../../components/structure/Footer/footer';
@import '../../components/links/LinkIcon/link-icon';
@import '../../components/images/Icon/icon';
@import '../../components/other/Social/social';
@import '../../components/other/Cutoff/cutoff';

//Content imports
@import '../../components/typography/Title/Title';
@import '../../components/links/Link/link';
@import '../../components/other/DelimiterMessage/delimiterMessage';

//Page styles
.add-phone {
    &__box {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
    }
}
