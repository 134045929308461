@import '../../../assets/global-style/mixin';

.loader {
    &:not(:last-child) {
        margin-bottom: 24px;
    }

    &__container {
        padding: 24px;
        border: 2px dashed var(--color-gray-seventh);
        border-radius: 8px;

        @include before_768 {
            padding: 16px;
        }
    }

    &__flex {
        display: flex;
        align-items: flex-start;
    }

    &__icon-box {
        position: relative;
        flex-shrink: 0;
        width: 33px;
        height: 33px;
        margin-right: 20px;

        @include before_768 {
            margin-right: 12px;
        }
    }

    &__icon {
        position: absolute;
        left: 0;
        top: 0;
        opacity: 1;
        transition: opacity 0.5s;

        &.js-loader-icon--complete {
            opacity: 0;
        }
    }

    &__files-list {
        margin-bottom: 12px;
    }

    &__name {
        margin-bottom: 12px;
        font-weight: 300;
        font-size: 17px;
        line-height: 110%;
        color: var(--text-primery-color);

        @include before_768 {
            font-size: 15px;
        }
    }

    &__button {
        display: none;
        padding: 10px 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;
        color: var(--text-primery-color);
        text-decoration: none;
        text-align: center;
        background-color: var(--color-white-first);
        border: 1px solid var(--color-gray-first);
        border-radius: 8px;

        box-sizing: border-box;
        cursor: pointer;
        outline: none;
        transition: opacity 0.5s;

        &:hover {
            opacity: 0.6;
        }

        &--file {
            display: block;
            background-color: var(--color-gray-second);
            border: 1px solid var(--color-gray-second);

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }

    &__button-flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__button-text {
        margin-right: 10px;
    }

    &__button-icon {
        flex-shrink: 0;
    }

    &__format {
        margin-top: 12px;
        font-size: 13px;
        line-height: 150%;
        color: var(--color-black-first-rgba50);
    }

    &.js-loader-error {
        .loader__container {
            border-color: var(--error-primery-color);
        }
    }

    &.js-loader-successful {
        .js-loader-icon-load {
            opacity: 0;
        }

        .js-loader-icon--complete {
            opacity: 1;
        }
    }

    .js-error-message {
        display: none;
        margin-top: 8px;
        font-size: 13px;
        line-height: 150%;
        color: var(--error-primery-color);
    }
}
