@import '../../../assets/global-style/mixin';

.cell-select {
    position: relative;
    width: 100%;
    z-index: 10;

    &__header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--color-white-first);
        text-align: center;
    }

    &__current {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        color: var(--color-black-third);
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.32, 0, 0.67, 0);

        @include before_768 {
            font-size: 14px;
        }
    }

    &__icon {
        fill: var(--color-gray-first);
        transition: 0.5s;

        @include before_768 {
            width: 14px;
            height: auto;
        }
    }

    &__body-icon {
        opacity: 0;
        fill: var(--color-gray-first);
        transition: 0.2s;
    }

    &__body {
        display: none;
        position: absolute;
        left: 50%;
        right: 0;
        top: calc(100% + 20px);
        width: calc(100% + 26px);
        max-height: 300px;
        overflow-y: auto;
        transform: translate(-50%, 0);
        background-color: #ffffff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }

    &.is-active {
        z-index: 11;

        .cell-select__icon {
            transform: rotate(180deg);
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        padding: 12px 16px;
        color: var(--color-gray-first);
        transition: color 0.5s;

        @include before_768 {
            font-size: 14px;
        }

        &:hover {
            background-color: #f2f2f2;
        }

        &.is-chosen {
            color: var(--color-black-third);

            .cell-select__body-icon {
                opacity: 1;
            }
        }
    }
}
