@import '../../../assets/global-style/mixin';

.cell-fias {
    position: relative;
    width: 100%;
    z-index: 10;

    &__header {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: var(--color-white-first);
        text-align: center;
    }

    &__current {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        color: var(--color-black-third);
        opacity: 0;
        transition: opacity 0.5s cubic-bezier(0.32, 0, 0.67, 0);
    }

    &__icon {
        fill: var(--color-gray-first);
        transition: 0.5s;
    }

    &__body-icon {
        opacity: 0;
        fill: var(--color-gray-first);
        transition: 0.2s;
    }

    &__body {
        display: none;
        position: absolute;
        left: 50%;
        right: 0;
        top: calc(100% + 20px);
        width: calc(100% + 48px);
        max-height: 300px;
        overflow-y: auto;
        transform: translate(-50%, 0);
        background-color: #ffffff;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
    }

    &.is-active {
        z-index: 11;

        .js-cell-select-body {
            display: block;
        }
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        font-weight: normal;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.04em;
        padding: 12px 16px;
        color: var(--color-gray-first);
        transition: color 0.5s;

        &:hover {
            background-color: #f2f2f2;
        }

        &.is-chosen {
            color: var(--color-black-third);

            .cell-select__body-icon {
                opacity: 1;
            }
        }
    }

    &__input {
        width: 100%;
        padding: 0;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: var(--text-primery-color);
        border: none;
        outline: none;
        background-color: transparent;

        @include before_768 {
            font-size: 14px;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.04em;
            color: var(--text-primery-color);

            @include before_768 {
                font-size: 14px;
            }
        }
    }
    // &__input {
    //     width: 100%;
    //     padding: 0;
    //     font-size: 16px;
    //     line-height: 26px;
    //     letter-spacing: 0.04em;
    //     color: var(--text-primery-color);
    //     border: none;
    //     outline: none;
    //     background-color: transparent;

    //     &::placeholder {
    //         font-size: 16px;
    //         line-height: 26px;
    //         letter-spacing: 0.04em;
    //         color: var(--text-primery-color);
    //     }
    // }

    // &__list {
    //     position: absolute;
    //     left: 50%;
    //     right: 0;
    //     top: calc(100% + 20px);
    //     width: calc(100% + 48px);
    //     max-height: 300px;
    //     overflow-y: auto;
    //     transform: translate(-50%, 0);
    //     background-color: #ffffff;
    //     box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    //     border-radius: 10px;
    // }

    // &__address {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     cursor: pointer;
    //     font-weight: normal;
    //     font-size: 16px;
    //     line-height: 26px;
    //     letter-spacing: 0.04em;
    //     padding: 12px 16px;
    //     color: var(--color-gray-first);
    //     transition: color 0.5s;

    //     &:hover {
    //         background-color: #f2f2f2;
    //     }
    // }
}
