@import '../../../assets/global-style/mixin';

.cell-textarea {
    width: 100%;
    padding: 0;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.04em;
    color: var(--text-primery-color);
    border: none;
    outline: none;
    background-color: transparent;
    resize: none;

    @include before_768 {
        font-size: 14px;
    }

    &::placeholder {
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: var(--color-gray-first);

        @include before_768 {
            font-size: 14px;
        }
    }
}
