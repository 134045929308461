@import '../../../assets/global-style/mixin';

.cutoff {
    height: 1px;
    margin-bottom: 45px;
    background-color: var(--color-gray-fourth-rgba20);
    border: none;

    &--motivation {
        margin-top: 80px;

        @include before_768 {
            margin-top: 40px;
        }
    }
}
