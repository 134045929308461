@import '../../../assets/global-style/mixin';

.multi-date {
    display: flex;
    align-items: baseline;

    &__text {
        margin-right: 5px;
    }

    &__input {
        width: 100px;
        padding: 0;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.04em;
        color: var(--text-primery-color);
        border: none;
        outline: none;
        background-color: transparent;

        @include before_768 {
            font-size: 14px;
        }

        &::placeholder {
            font-size: 16px;
            line-height: 140%;
            letter-spacing: 0.04em;
            color: var(--text-primery-color);

            @include before_768 {
                font-size: 14px;
            }
        }

        &[disabled] {
            opacity: 0.5;
        }
    }
}
