.grid {
    display: flex;
    flex-direction: column;
    min-height: 100vh;

    &__main {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding-bottom: 100px;

        @include before_768 {
            padding-bottom: 50px;
        }
    }

    &__content {
        display: flex;
        flex-grow: 1;
    }
}
