@import '../../../assets/global-style/mixin';

.navigation {
    position: relative;
    margin-bottom: 32px;
    padding-top: 36px;

    @include before_768 {
        margin-bottom: 0;
        padding-top: 16px;
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__block {
        display: flex;
        align-items: center;
    }

    &__link {
        position: relative;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: var(--color-gray-first);
        text-decoration: none;
        transition: opacity 0.5s;

        &:not(:last-child) {
            margin-right: 24px;
        }

        &:hover {
            opacity: 0.6;
        }

        &--user {
            display: flex;
            align-items: center;
            color: var(--color-blue-first);

            &:hover {
                opacity: 1;
            }
        }

        &--support {
            cursor: pointer;
            &:hover {
                opacity: 1;
            }
        }

        &--desktop {
            @include before_768 {
                display: none;
            }

            &.link-icon {
                @include before_768 {
                    display: none;
                }
            }
        }
    }

    &__link-el {
        transition: opacity 0.5s;
        cursor: pointer;

        &:first-child {
            margin-right: 8px;
        }

        &--text {
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.02em;
        }

        &--svg {
            flex-shrink: 0;
            fill: var(--icon-primery-color);
        }

        &--name {
            @include before_1024 {
                display: none;
            }
        }
    }

    &__logo-link {
        display: block;
        line-height: 10px;
    }

    &__logo {
        @include before_1024 {
            width: 180px;
            height: auto;
        }
    }

    &__popup {
        position: absolute;
        top: 100%;
        right: 0;
        transform: translate(0, 40px);
        min-width: 220px;
        padding: 16px;
        border-radius: 12px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
        background-color: var(--color-white-first);
        transition: 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        z-index: 10;

        @include before_1024 {
            position: fixed;
            top: 0;
            right: 0;
            transform: translate(100%, 0);
            min-width: 290px;
            height: 100vh;
            border-radius: 0;
            box-shadow: none;
            opacity: 1;
            visibility: visible;
        }

        &.js-active {
            opacity: 1;
            visibility: visible;
            transform: translate(0, 10px);
            z-index: 10;

            @include before_1024 {
                transform: translate(0, 0);
                box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.2);
            }
        }
    }

    &__menu-item {
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &__link-icon {
        display: inline-flex;
        align-items: center;
        padding: 0;
        font-family: var(--font-primery);
        text-decoration: none;
        background-color: transparent;
        border: none;
        border-radius: 0;
        box-sizing: border-box;
        cursor: pointer;
        outline: none;
        transition: 0.5s;
        webkit-appearance: none;

        &-el {
            &:first-child {
                margin-right: 8px;
            }

            &--box {
                width: 20px;
            }

            &--text {
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0.02em;
                color: var(--color-gray-first);
                transition: color 0.5s;
            }
        }

        &-svg {
            display: block;
            flex-shrink: 0;
            fill: var(--icon-primery-color);
            transition: 0.5s;
        }

        &:hover {
            .navigation__link-icon-el--text {
                color: var(--text-primery-color);
            }

            .navigation__link-icon-svg {
                fill: var(--text-primery-color);
            }
        }
    }

    &__popup-header {
        display: none;

        @include before_1024 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--color-gray-third);
        }
    }

    &__popup-close {
        fill: var(--color-gray-first);
        cursor: pointer;
    }
}
