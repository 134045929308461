@import '../../../assets/global-style/mixin';

.social {
    display: flex;
    align-items: center;

    &__item {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--color-gray-fourth);
        border-radius: 50%;
        transition: opacity 0.5s;

        a {
            max-height: 20px;
        }

        &:not(:last-child) {
            margin-right: 16px;
        }

        &:hover {
            opacity: 0.7;
        }
    }
}
