@import '../../../assets/global-style/mixin';

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px;

    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #ffffff;
    text-decoration: none;
    text-align: center;

    background-color: var(--button-primery-color);
    border: 1px solid var(--button-primery-color);
    border-radius: 8px;

    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    transition: 0.5s;
    webkit-appearance: none; //убирает дефолтные стили в ios

    @include before_768 {
        padding: 15px 20px;
        font-size: 14px;
    }

    &:hover {
        color: var(--button-primery-color);
        background-color: var(--color-white-first);
    }

    &:active {
        opacity: 0.5;
        transition: 0.1s;
    }

    &:focus {
        opacity: 0.9;
        transition: 0.1s;
    }

    &.disabled {
        background-color: var(--color-gray-sixth);
        border-color: var(--color-gray-sixth);
        pointer-events: none;
        cursor: not-allowed;
    }

    &.hidden {
        display: none;
    }

    //Белая с серыми рамками и цветом текста
    &--type1 {
        padding: 15px 30px;
        color: var(--color-gray-sixth);
        background-color: var(--color-white-first);
        border-color: var(--color-gray-sixth);

        &:hover {
            color: var(--color-white-first);
            background-color: var(--button-primery-color);
            border-color: var(--button-primery-color);
        }
    }

    //Белая с серыми рамками и черным цветом текста
    &--type2 {
        padding: 15px 30px;
        color: var(--text-primery-color);
        background-color: var(--color-white-first);
        border-color: var(--color-gray-sixth);

        &:hover {
            color: var(--color-white-first);
            background-color: var(--button-primery-color);
            border-color: var(--button-primery-color);
        }
    }

    //Белая с синими рамками и синим цветом текста
    &--type3 {
        padding: 15px 30px;
        color: var(--color-blue-third);
        background-color: var(--color-white-first);
        border-color: var(--color-blue-third);

        &:hover {
            color: var(--color-white-first);
            background-color: var(--button-primery-color);
            border-color: var(--button-primery-color);
        }
    }

    //Голубая с голубой рамкой, синим цветом текста и его размером 14px
    &--type4 {
        padding: 12px;
        font-size: 14px;
        color: var(--color-blue-first);
        background-color: rgba(63, 179, 255, 0.2);
        border-color: rgba(63, 179, 255, 0.2);

        &:hover {
            color: var(--color-white-first);
            background-color: var(--button-primery-color);
            border-color: var(--button-primery-color);
        }
    }

    //Белая с серыми рамками, цветом текста и его размером 14px
    &--type5 {
        padding: 12px;
        font-size: 14px;
        color: var(--color-gray-sixth);
        background-color: var(--color-white-first);
        border-color: var(--color-gray-sixth);

        &:hover {
            color: var(--color-white-first);
            background-color: var(--button-primery-color);
            border-color: var(--button-primery-color);
        }
    }

    //Синия с уменьшиным паддингом и шрифтом
    &--type6 {
        padding: 12px;
        font-size: 14px;
    }

    &--100 {
        width: 100%;
    }

    &--mb24 {
        margin-bottom: 24px;
    }

    &--center {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0);
    }
}
